<template>
  <div>
    <vue-html2pdf
      ref="html2Pdf"
      class="my-export"
      :show-layout="false"
      :preview-modal="true"
      :enable-download="false"
      :manual-pagination="false"
      :paginate-elements-by-height="10"
      filename="doc"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="auto"
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="assignLodingTime($event)"
      @hasDownloaded="hasDownloaded"
    >
      <section
        id="my-pdf"
        ref="pdfContent"
        slot="pdf-content"
        style="letter-spacing: 0.01rem"
      ></section>
    </vue-html2pdf>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import { mapActions, mapGetters, mapMutations } from "vuex";

import VueHtml2pdf from "vue-html2pdf";

import Loading from "@/components/Loading";

export default {
  name: "pdfExport",
  components: {
    VueHtml2pdf,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      isUpload: false,
      patientId: null,
      remark: "",

      //html2pdf
      htmlToPdfOptions: {
        pagebreak: { mode: "avoid-all" },
        image: { type: "jpeg", quality: 0.98 },
        // letterRendering: true,

        html2canvas: {
          dpi: 300,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
        },
      },

      loadingTime: 0,
    };
  },
  created() {
    this.init();
  },
  computed: {},
  watch: {},
  methods: {
    ...mapActions({
      createMediaFiles: "moduleMediaFile/createMediaFiles",
    }),
    init() {},

    async generatePdf({ header, body, footer, option }) {
      console.log(option);
      this.isLoading = true;
      this.isUpload = option?.isUpload || false;
      this.patientId = option?.patientId || null;
      this.remarkTitle = option?.remarkTitle || "";
      this.htmlToPdfOptions.jsPDF.orientation =
        option?.orientation || "portrait";
      document.getElementById("my-pdf").style.fontSize =
        option?.fontSize || "13px";

      const pdfContent = this.$refs.pdfContent;

      if (header) {
        await pdfContent.appendChild(header.cloneNode(true));
        // pdfContent.appendChild(hr);
      }
      if (body) {
        await pdfContent.appendChild(body.cloneNode(true));
      }
      if (footer) {
        await pdfContent.appendChild(footer.cloneNode(true));
      }
      await this.$nextTick();

      let data = await this.$refs.html2Pdf.generatePdf();
      pdfContent.innerHTML = "";
    },

    assignLodingTime(e) {
      this.loadingTime = e;
    },
    async hasDownloaded(pdfBlob) {
      console.log("hasGen");

      this.resetLodingTime();
      if (!this.isUpload) return;
      console.log("b4handleCreateƒ");
      await this.handleCreateMediaFile(pdfBlob);
      eventBus.$emit("pollForUpdates", [pdfBlob]);
    },
    resetLodingTime() {
      this.isLoading = false;
      setTimeout(() => {
        this.loadingTime = 0;
      }, 1000);
    },
    async handleCreateMediaFile(pdfBlob) {
      await this.createMediaFiles({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        data: {
          category: "Document",
          patientId: this.patientId ? String(this.patientId) : null,
          remark: `${this.remarkTitle}${this.$utils.generateDateTimeString()}`,
          filesMetadata: [{ id: null, contentType: "application/pdf" }],
        },
        files: [pdfBlob],
      });
    },
  },
  mounted() {
    const containerEl = document.querySelector(
      ".vue-html2pdf.my-export .layout-container .content-wrapper"
    );
    containerEl.style.setProperty("margin", "0", "important");
    containerEl.style.setProperty("background-color", "white", "important");
    // eventBus.$on("generatePDF", (content, header, footer) => {
    //   this.generatePDF(content, header, footer);
    // });
  },
  updated() {},
  beforeDestroy() {
    eventBus.$off("generatePDF");
  },
};
</script>

<style>
#my-pdf {
  font-size: 13px;
  margin: 3rem 3rem;
}
</style>
