<template>
  <div>
    <b-card
      :class="cardClass"
      v-if="filteredList.length > 0"
      body-class="p-2"
      border-variant="primary"
    >
      <p v-if="label" class="ml-2 mb-1">
        <strong>{{ label }}</strong>
        <span v-if="isMaxSelected" class="description text-danger">
          เลือกได้ไม่เกิน {{ maxSelection }} รูป
        </span>
      </p>

      <b-row class="px-3">
        <b-col
          v-for="(item, index) in filteredList"
          :key="item.id"
          class="p-1"
          :cols="photoSize === 'xs' ? 12 : photoSize === 'sm' ? 6 : 12"
          :md="photoSize === 'xs' ? 6 : photoSize === 'sm' ? 3 : 6"
          :lg="photoSize === 'xs' ? 6 : photoSize === 'sm' ? 2 : 4"
        >
          <div class="img-wrap">
            <b-form-checkbox
              v-if="checkbox"
              v-model="selectedImages[item.id]"
              size="lg"
              style="position: absolute; top: 5px; left: 5px"
              @change="selectImage"
              @click.stop.prevent
              :disabled="isMaxSelected && selectedImages[item.id] !== true"
            />
            <b-badge
              v-if="deleteButton"
              class="img-delete-button show-when-hovered"
              variant="danger"
              href="#"
              @click.stop="deleteImg(item)"
              >&times;</b-badge
            >
            <b-img
              thumbnail
              fluid
              :src="item.src"
              :alt="item.title"
              :style="imgStyle"
              @click="handleThumbnailClick(index, filteredList)"
            ></b-img>
            <p
              class="mb-0 text-center description"
              v-b-tooltip.hover.right
              :title="item.remark"
            >
              {{ truncateText(item.remark) }}
            </p>
          </div>
        </b-col>
      </b-row>

      <Lightbox
        :index="lightBoxIndex"
        :items="lightBoxList"
        @close="resetList"
      />
    </b-card>
    <Dialog ref="Dialog"></Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";
import VueLightbox from "vue-lightbox-lite";

import "vue-lightbox-lite/dist/index.css";
import truncateText from "@/utils/truncateText";
import Dialog from "@/components/modal/Dialog";

import moment from "moment";

export default {
  name: "PhotoGallery",
  components: { Lightbox: VueLightbox, Dialog },
  props: {
    label: String,
    cardClass: String,
    imgStyle: String,
    photoSize: String,
    checkbox: Boolean,
    deleteButton: Boolean,
    maxSelection: Number,
    category: [String, Array],
    displayList: Array,
  },
  data() {
    return {
      fileList: [],
      formattedList: [],
      fileCategories: [
        { text: "Intra-Oral", value: "IntraOralPhoto" },
        { text: "Extra-Oral", value: "ExtraOralPhoto" },
        { text: "X-Ray", value: "XRay" },
        { text: "OPD Card", value: "OpdCard" },
        // { text: "Invoice", value: "Invoice" },
        // { text: "Document", value: "Document" },
        // { text: "Model3D", value: "Model3d" },
        // { text: "Video", value: "Video" },
      ],
      lightBoxIndex: null,
      lightBoxList: [],

      isLoading: false,
      isFetching: false,
      limit: 50,

      highestId: 0,
      pollInterval: null,
      continuePolling: true, // Flag to indicate whether to continue polling
      fetchAttempts: 0, // Counter to keep track of fetch attempts
      maxFetchAttempts: 3,
      uploadedFiles: null, // Maximum number of fetch attempts
      selectedImages: {},
      selectedIds: [],
      truncateText,
    };
  },
  computed: {
    ...mapGetters({
      getFileList: "moduleMediaFile/getFileList",
    }),
    list() {
      if (this.displayList?.length >= 0) return this.displayList;
      return this.getFileList;
    },
    filteredList() {
      if (typeof this.category === "string") {
        return this.list.filter(i => i.category === this.category);
      } else if (Array.isArray(this.category)) {
        return this.list.filter(i => this.category.includes(i.category));
      } else {
        return this.list;
      }
    },
    isMaxSelected() {
      if (this.maxSelection) {
        return this.selectedIds.length >= this.maxSelection;
      } else {
        return false;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({ deleteMediaFile: "moduleMediaFile/deleteMediaFile" }),

    handleThumbnailClick(key, list) {
      this.lightBoxIndex = key;
      this.lightBoxList = list;
    },
    resetList() {
      this.lightBoxIndex = null;
      this.lightBoxList = [];
    },
    selectImage() {
      // Emit an event with the selected image
      this.selectedIds = Object.keys(this.selectedImages)
        .filter(key => this.selectedImages[key] === true)
        .map(key => Number(key));
      this.$emit("imagesSelected", this.selectedIds);
    },
    async deleteImg(item) {
      try {
        const { isConfirmed } = await this.$refs.Dialog.showAlertConfirm(
          "ลบไฟล์นี้ ?",
          `คุณต้องการลบไฟล์นี้`
        );
        if (isConfirmed) {
          this.$set(item, "isDeleting", true);
          const res = await this.deleteMediaFile({
            clinicUrl: this.$route.params.clinicUrl,
            branchUrl: this.$route.params.branchUrl,
            id: item.id,
          });
          if (res.status === 200) {
            this.fileList = this.fileList.filter(i => {
              return item.id !== i.id;
            });
            this.formattedList = this.formatList();
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.$set(item, "isDeleting", false);
      }
    },
    formatList() {
      return this.fileList.map(i => {
        return {
          id: i.id,
          src: i.mediaURL,
          // thumb: i.thumbnailURL,
          title: i.category,
          category: i.category,
          mediaType: i.fileType === "application/pdf" ? "iframe" : null,
        };
      });
    },
  },
  async mounted() {},
};
</script>


<style>
.img-wrap {
  position: relative;
}
.img-wrap .img-delete-button {
  position: absolute;
  font-size: 1.2em;
  top: -5px;
  right: -3px;
  z-index: 100;
  cursor: pointer;
  display: none;
}
.img-wrap:hover .img-delete-button {
  display: block;
}
.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}
.min-height-100px {
  min-height: 100px;
}
</style>
