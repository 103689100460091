<template>
  <div>
    <b-modal
      modal-class="component-modal-preview-receipt"
      id="PaymentReceiptPreviewModal"
      ref="PaymentReceiptPreviewModal"
      size="xl fluid"
      centered
      @hide="closeReceipt"
      @hidden="defaultValue"
      :hide-footer="mode == 'cancel' || displayOnly"
    >
      <template #modal-title>
        พิมพ์ใบเสร็จรับเงิน
        <i id="receipt-setting-gear" class="fas fa-gear"></i>
      </template>
      <b-popover
        target="receipt-setting-gear"
        triggers="hover"
        placement="top"
        title="แสดงคอลัมน์"
      >
        <b-checkbox
          v-for="field in displayFields"
          :key="field.key"
          v-model="field.visible"
          switch
          @change="saveSetting"
        >
          {{ field.label }}
        </b-checkbox>
      </b-popover>
      <b-tabs v-model="tabIndex" card justified>
        <b-tab title="ภาษาไทย"
          ><ReceiptPreview
            :ref="tabRefs[0]"
            :lang="locale[0]"
            :afterPrint="afterPrint"
            :createReceiptSuccess="createReceiptSuccess"
            containerTarget="#print"
            :mode="mode"
            :receiptData="receipt"
            :patientData="patient"
            :displayKeys="displayKeys"
        /></b-tab>
        <b-tab title="English"
          ><ReceiptPreview
            :ref="tabRefs[1]"
            :lang="locale[1]"
            :afterPrint="afterPrint"
            :createReceiptSuccess="createReceiptSuccess"
            containerTarget="#print"
            :mode="mode"
            :receiptData="receipt"
            :patientData="patient"
            :displayKeys="displayKeys"
        /></b-tab>
      </b-tabs>
      <template #modal-footer>
        <div class="w-100">
          <div class="row">
            <div v-if="mode === 'original'" class="col-12 d-flex">
              <b-button
                variant="primary"
                class="col-3 mx-auto"
                @click="issueOriginalReceipt"
              >
                พิมพ์ใบเสร็จ
              </b-button>
            </div>

            <div v-else class="col-12 d-flex">
              <b-button
                v-if="showCancelReceipt"
                variant="danger"
                class="col-3 mx-auto"
                @click="cancelReceipt"
              >
                ยกเลิกใบเสร็จ
              </b-button>
              <b-button
                variant="primary"
                class="col-3 mx-auto"
                @click="debouncedPrintReceipt"
              >
                <img
                  v-if="getNhsoBookingNo && mode === 'create' && !isSaved"
                  class="mr-1"
                  style="width: auto; height: 24px"
                  src="@/assets/img//NHSO_logo.png"
                />
                <img
                  v-if="isPaotang"
                  class="mr-1"
                  style="width: auto; height: 24px"
                  src="@/assets/img/Paotang-small.png"
                />
                {{ printButtonText }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <VerifyModal ref="verifyModal" :afterCancel="afterCancel" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import VerifyModal from "@/components/modal/VerifyCancelReceipt";
import ReceiptPreview from "@/components/ReceiptPreview";
import isEmpty from "lodash/isEmpty";
import { eventBus } from "@/main";
import debounce from "@/utils/debounce";

export default {
  name: "PaymentReceiptPreviewModal",
  components: {
    VerifyModal,
    ReceiptPreview,
  },
  data() {
    return {
      tabIndex: 0,
      tabRefs: ["ReceiptTh", "ReceiptEn"],
      locale: ["th", "en"],
      receipt: {},
      patient: {},
      mode: "create",
      isSaved: false,
      showCancelReceipt: false,
      displayOnly: false,

      displayFields: [
        {
          key: "doctor",
          label: "แพทย์",
          visible: true,
        },
        {
          key: "quantity",
          label: "จำนวน",
          visible: true,
        },
        {
          key: "price",
          label: "ราคา",
          visible: true,
        },
        {
          key: "finalDiscount",
          label: "ส่วนลด",
          visible: true,
        },
      ],
      displayKeys: ["doctor", "quantity", "price", "finalDiscount"],
      debouncedPrintReceipt: null,
    };
  },
  computed: {
    ...mapGetters({
      getNhsoBookingNo: "moduleAppointment/getNhsoBookingNo",
    }),
    printButtonText() {
      if (this.mode == "create" && !this.isSaved) {
        if (this.getNhsoBookingNo) return "ส่งข้อมูล สปสช และบันทึกใบเสร็จ";
        if (this.isPaotang) return "ชำระด้วยเป๋าตัง";
        return "บันทึกและพิมพ์ใบเสร็จ";
      }
      return "พิมพ์ใบเสร็จ";
    },
    isPaotang() {
      return this.receipt?.displayAmount.filter(i => i.cateId === 6).length > 0;
    },
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchPatientById: "modulePatient/fetchPatientById",
      fetchReceiptById: "moduleReceipt/fetchReceiptById",
      issueReprint: "moduleReceipt/issueReprint",
    }),

    fetchAndShow({ id, patientId, cancel }) {
      const receiptPromise = this.fetchReceiptById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id,
      });
      const patientPromise = this.fetchPatientById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: patientId,
      });

      this.isLoading = true;
      Promise.all([receiptPromise, patientPromise])
        .then(responseAll => {
          if (responseAll) {
            const [receiptRes, patientRes] = responseAll;
            if (receiptRes.status == 200 && patientRes.status == 200) {
              if (receiptRes.data.id && patientRes.data.id) {
                this.show(
                  receiptRes.data,
                  patientRes.data,
                  cancel ? "cancel" : "copy",
                  this.isManager || this.isHeadCounter
                );
              } else {
                eventBus.$emit("alertSwal", {
                  title: "ระบบขัดข้อง",
                  message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
                  icon: "error",
                });
              }
            }
          }
        })
        .catch(err => {
          console.error("catch:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    show(receiptData, patientData, mode, showCancelReceipt, displayOnly) {
      this.receipt = receiptData;
      this.patient = patientData;
      this.mode = mode || "create";
      this.showCancelReceipt = showCancelReceipt && this.mode == "copy";
      this.displayOnly = displayOnly || false;
      this.loadSetting();

      this.$refs["PaymentReceiptPreviewModal"].show();
    },

    showOriginalReceipt(receiptData, patientData, mode) {
      this.receipt = receiptData;
      this.patient = patientData;
      this.mode = "original";
      this.loadSetting();
      this.$refs["PaymentReceiptPreviewModal"].show();
    },
    async issueOriginalReceipt() {
      try {
        this.$refs[this.tabRefs[this.tabIndex]].print();
        await this.issueReprint({
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          data: {
            id: this.receipt.id,
          },
        });
      } catch (err) {
        console.error(err);
      }
    },

    hide() {
      this.$refs["PaymentReceiptPreviewModal"].hide();
    },
    printReceipt() {
      this.$refs[this.tabRefs[this.tabIndex]].print(this.isPaotang);
    },
    createReceiptSuccess() {
      this.isSaved = true;
    },
    cancelReceipt() {
      if (this.mode == "copy" && !isEmpty(this.receipt)) {
        this.$refs.verifyModal.show(this.receipt.id);
      }
    },
    closeReceipt() {
      if (this.isSaved) {
        this.$emit("afterClose");
      }
    },
    afterPrint() {
      this.$emit("afterPrint");
      this.hide();
    },
    afterCancel() {
      this.hide();
      this.$emit("afterCancel");
      eventBus.$emit("afterCancelReceipt");
    },
    getKeys() {
      return this.displayFields.filter(i => i.visible).map(i => i.key);
    },
    async saveSetting() {
      await this.$nextTick();
      this.displayKeys = this.getKeys();
      localStorage.setItem(
        "receiptDisplayKeys",
        JSON.stringify(this.displayKeys)
      );
    },
    async loadSetting() {
      this.displayKeys = JSON.parse(
        localStorage.getItem("receiptDisplayKeys")
      ) || ["doctor", "quantity", "price", "finalDiscount"];
      this.displayFields.forEach(i => {
        if (this.displayKeys.includes(i.key)) return;
        i.visible = false;
      });
    },
    defaultValue() {
      this.receipt = {};
      this.patient = {};
      this.mode = "create";
      this.isSaved = false;
      this.showCancelReceipt = false;
      this.displayOnly = false;
    },
  },
  mounted() {
    this.debouncedPrintReceipt = debounce(this.printReceipt);
  },
};
</script>

<style>
.component-modal-preview-receipt .modal-body {
  padding: 0;
}
</style>