<template>
  <b-modal
    id="verifyCancelReceiptModal"
    ref="verifyCancelReceiptModal"
    size="sm"
    centered
    hide-header
    title="ยกเลิกใบเสร็จ"
    variant="danger"
    @hidden="defaultValue"
  >
    <div>
      <b-form
        id="verifyForm"
        ref="verifyForm"
        @submit.stop.prevent="handleCancelReceipt"
      >
        <b-form-group label="Password:">
          <b-form-input
            placeholder="กรุณาใส่พาสเวิร์ด"
            required
            type="password"
            autocomplete="new-password"
            v-model="password"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="สาเหตุในการยกเลิกใบเสร็จ:">
          <b-form-textarea
            placeholder=""
            required
            v-model="remark"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </div>
    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        type="submit"
        form="verifyForm"
      >
        ยกเลิกใบเสร็จ
      </b-button>
    </template>
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Dialog from "@/components/modal/Dialog";
import Loading from "@/components/Loading";

export default {
  name: "VerifyCancelReceiptModal",
  components: {
    Dialog,
    Loading,
  },
  props: {
    afterCancel: Function,
  },
  data() {
    return {
      isLoading: false,
      receiptId: null,
      password: null,
      remark: "",
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "moduleUser/getUserInfo",
    }),
  },
  watch: {},
  methods: {
    ...mapActions({
      cancelReceipt: "moduleReceipt/cancelReceipt",
      verifyUser: "moduleUser/verifyUser",
    }),
    show(receiptId) {
      this.receiptId = receiptId ?? null;
      this.$refs["verifyCancelReceiptModal"].show();
    },
    close() {
      this.$bvModal.hide("verifyCancelReceiptModal");
    },
    handleVerify() {
      this.isLoading = true;
      this.verifyUser({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        username: this.userInfo.email,
        password: this.password,
      })
        .then((res) => {
          this.isLoading = false;
          if (res.data.success) {
            this.handleCancelReceipt();
          } else {
            this.$refs.Dialog.showToast("danger", "รหัสผ่านไม่ถูกต้อง");
          }
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    },
    handleCancelReceipt() {
      const clinicUrl = this.$route.params.clinicUrl;
      const branchUrl = this.$route.params.branchUrl;
      const data = {
        id: this.receiptId,
        cancelRemark: this.remark,
        password: this.password,
      };
      this.isLoading = true;
      this.cancelReceipt({
        data: data,
        clinicUrl,
        branchUrl,
      })
        .then(async (res) => {
          if (this.afterCancel) {
            await this.afterCancel();
          }
          this.close();
        })
        .catch((err) => {
          if (err?.response?.status === 403) {
            this.$refs.Dialog.showToast("danger", "รหัสผ่านไม่ถูกต้อง");
            return;
          }
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    defaultValue() {
      this.receiptId = null;
      this.password = null;
      this.remark = "";
    },
  },
};
</script>

<style>
</style>