export default function debounce(func, delay = 1000) {
  let timeoutId;
  let lastExecTime = 0;
  return function(...args) {
    const context = this;
    const currentTime = Date.now();
    const elapsedTime = currentTime - lastExecTime;
    const timeToWait = Math.max(delay - elapsedTime, 0);

    clearTimeout(timeoutId);
    if (elapsedTime >= delay) {
      lastExecTime = currentTime;
      func.apply(context, args);
    } else {
      timeoutId = setTimeout(() => {
        lastExecTime = Date.now();
        func.apply(context, args);
      }, timeToWait);
    }
  };
}
